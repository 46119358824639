<template>
  <graph
    class="dispute-approved-reason"
    v-if="account && approvedReasons.length"
    width="100%"
    height="175px"
    :options="chartOptions"
    :series="series"
  />
</template>
<script>
export default {
  components: {
    graph: () => import('@/components/graph')
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar'
        },
        dataLabels: {
          enabled: true,
          formatter: v => this.formatCurrency(v, this.account.currency),
          offsetY: -20
        },
        labels: this.labels,
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        xaxis: {
          type: 'string',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        title: {
          text: this.$t('Approved Reasons')
        },
        tooltip: {
          enabled: false,
          intersect: false
        }
      }
    },
    labels () {
      return this.approvedReasons.map(v => v.text)
    },
    approvedReasons () {
      return this.account?.disputes?.approvedReasons || []
    },
    series () {
      return [{ data: this.approvedReasons.map(v => v.amount) }]
    }
  },
  props: {
    account: Object
  }
}
</script>
<style lang="stylus" scoped>
.dispute
  display flex
</style>
